<template>
    <div class="card">

        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="false"
                :showAddNew="true"
                title="Sales Register"
                btnTitle="Export"
                @onClickAddNewButton="onClickToExport"
            />
        </div>

        <div class="my-2 px-2">
            <DateQuerySetter
                btnTitle="Go"
                @onClickGo="onClickGo"
            >
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mr-top-1">
                    <label class="form-label">Select BDO</label>
                    <AsyncSelect
                        placeholder="Select BDO"
                        v-model="singleSR"
                        :api-service="fetchAllSR"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mr-top-1">
                    <label for="colFormLabel" class="form-label">Bank-Branch</label>
                    <AsyncSelect
                        placeholder="Select Bank-Branch"
                        v-model="singleDealer"
                        :api-service="fetchAllDealers"
                        :additional-query="additionalDealerQuery"
                        :additional-option="additionalDealerOption"
                        :key="dealerRenderKey"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div class="d-flex align-items-center justify-content-between filter-label">
                        <label for="colFormLabel" class="col-form-label">Client: </label>
                        <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                            <i class="fas fa-search"></i>
                        </span>
                    </div>
                    <AsyncSelect
                        placeholder="Select Client"
                        v-model="contactProfile"
                        :api-service="fetchContactProfiles"
                        :additional-query="additionalContactOption"
                        :format-label="formatPatientLabel"
                        :additional-option="additionalOption"
                        :key="contactRenderKey"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mr-top-1">
                    <label class="form-label">Select location</label>
                    <AsyncSelect
                        placeholder="Select location"
                        v-model="area"
                        :api-service="fetchAreaList"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mr-top-1">
                    <label class="form-label">Order Type</label>
                    <v-select
                        placeholder="Select Order Type"
                        v-model="saleType"
                        :options="orderTypes"
                        label="name"
                        :reduce="name => name.value"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mr-top-1">
                    <label class="form-label">Order NO</label>
                    <input
                        class="form-control"
                        type="text"
                        v-model="orderNumber"
                        name="order_number"
                        placeholder="Order Number"
                    >
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mr-top-1">
                    <label class="form-label">Invoice NO</label>
                    <input
                        class="form-control"
                        type="text"
                        v-model="invoiceNumber"
                        name="order_number"
                        placeholder="Order Number"
                    >
                </div>
            </DateQuerySetter>
        </div>

        <div class="col-12 px-2 mt-2 mb-4">
            <ListTable
                :tableItems="tableItems"
            />
        </div>

        <div class="px-2 position-absolute bottom-0 mt-4">
            <Pagination :offset="params.offset" @onPageChange="onPageChange" ref="pagination"/>
        </div>

        <Loader v-if="loading"/>

        <ContactDropdownFilter 
            v-if="store.state.isModalOpenThree"
            @onSearchContact="onSearchContact"
            :type="'customer'"
        />

    </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import { computed, inject, onMounted, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import Loader from '@/components/atom/LoaderComponent'
import Pagination from '@/components/atom/Pagination'
import ListTable from '@/components/molecule/company/inventory/sales/SalesRegisterTable.vue'
import DateQuerySetter from '@/components/atom/DateQuerySetter.vue';
import handleCrm from '@/services/modules/crm/crm.js'
import handleContact from "@/services/modules/contact";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import ContactDropdownFilter from '@/components/atom/Contact/ContactDropdownFilter.vue'
import handlePurchase from '@/services/modules/purchase'

const store = useStore();
const route = useRoute();
const router = useRouter();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false)
const tableItems = ref([]);
const params = reactive({
    searchTxt: '',
    offset: 10
});

const contactProfile = ref(null)
const singleSR = ref(null);
const singleDealer = ref(null);
const area = ref(null);
const dealerRenderKey = ref(0);
const contactRenderKey = ref(0);
const saleType = ref(null);

const additionalOption = ref([]);
const additionalDealerQuery = ref({});
const additionalContactOption = ref({
    with_mobile: 1,
    dealer_id: ''
});
const additionalDealerOption = ref([]);
const orderTypes = ref([]);

const { fetchAllDealers, fetchAllSR, fetchAreaList } = handleCrm();
const { fetchContactProfiles } = handleContact();
const { formatPatientLabel } = useAsyncDropdownHelper();
const { fetchSalesRegisterList, fetchSalesOrderTypes } = handlePurchase()

const start = computed(() => route.query.start);
const end = computed(() => route.query.end);

const orderNumber = ref('');
const invoiceNumber = ref('');

watch(() => singleSR.value, (newValue, oldValue) => {
    additionalDealerQuery.value = {};
    additionalContactOption.value.dealer_id = '';
    singleDealer.value = null;
    contactProfile.value = null;
    dealerRenderKey.value += 1;
    contactRenderKey.value += 1;

    if(newValue) {
        additionalDealerQuery.value = {'sale_representative_id': newValue.id}
    }
})

watch(() => singleDealer.value, (newValue) => {
    additionalContactOption.value.dealer_id = '';
    contactProfile.value = null;
    contactRenderKey.value += 1;

    if(newValue) {
        additionalContactOption.value.dealer_id = newValue.id
    }
})

const onClickToExport = () => {
    let token = localStorage.getItem('token');
    let url = `${process.env.VUE_APP_BASE_URL}/export/inventory/sales-register${getQuery()}&_token=${token}&is_export=1`;
    let a = document.createElement("a")
    a.setAttribute("href", url)
    a.click()
}

const onOpenContactSearchModal = () => {
    store.state.isModalOpenThree = true;
}

const onSearchContact = (contact) => {
    contactProfile.value = contact
}

const onClickGo = () => {
    getSaleRegisterList()
}

const setPaginationData = (data) => {
    store.commit('setPaginationData', data)
};

const setPagination = (data) => {
    setPaginationData({
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
    })
}

async function onPageChange(page) {
    let routeQuery = Object.assign({}, route.query)
    routeQuery.page = page
    await router.push({path: route.path, query: routeQuery})
    await getSaleRegisterList();
}

const getSaleRegisterList = async () => {

    const query = getQuery();
    loading.value = true

    await fetchSalesRegisterList(query).then(res => {
        loading.value = false
        if (!res.status){
            tableItems.value = []
            store.commit("resetPagination")
            return
        }
        tableItems.value = res.data.data;
        setPagination(res.data)
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const getSaleOrderTypes = async () => {
    const res = await fetchSalesOrderTypes(`?company_id=${route.params.companyId}`);
    if (res.status) {
        orderTypes.value = res.data
    }
}

const getQuery = () => {
    let query = `?company_id=${route.params.companyId}`;
    if (start.value && end.value) query += `&start_date=${start.value}&end_date=${end.value}`
    if (route.query.page) query += '&page=' + route.query.page
    if(singleSR.value) query += `&sale_representative_id=${singleSR.value.id}`;
    if(singleDealer.value) query += `&dealer_id=${singleDealer.value.id}`;
    if(contactProfile.value) query += `&contact_profile_id=${contactProfile.value.id}`;
    if(area.value) query += `&area_id=${area.value.id}`;
    if(orderNumber.value) query += `&order_number=${orderNumber.value}`;
    if(invoiceNumber.value) query += `&invoice_number=${invoiceNumber.value}`;
    if(saleType.value) query += `&sale_type=${saleType.value}`
    query += '&offset=' + params.offset
    return query;
}

onMounted(() => {
    getSaleOrderTypes()
})
</script>

<style scoped>
.mr-top-1 {
    margin-top: 1% !important;
}
.filter-label {
    padding-right: 0.5rem !important;
}
</style>